import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { ReservationReason } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectState = createFeatureSelector<State>('reservationReasons');

export const selectAllReservationReasonsItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectReservationReasonById = (id: string) =>
  createSelector(
    selectAllReservationReasonsItems,
    (reservationReasons: ReservationReason[]) => {
      if (reservationReasons) {
        return reservationReasons.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectReservationReasonsError: MemoizedSelector<object, any> =
  createSelector(selectState, getError);

export const selectIsLoading: MemoizedSelector<object, boolean> =
  createSelector(selectState, getIsLoading);
