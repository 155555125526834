import { createAction, props } from '@ngrx/store';

import { ReservationReason } from '../../models';

export const createRequest = createAction(
  '[Reservation Reasons] Create Request',
  props<{
    item: Omit<ReservationReason, 'id'>;
    onSuccess?: () => any;
    onFailure?: () => any;
  }>(),
);

export const createSuccess = createAction(
  '[Reservation Reasons] Create Success',
  props<{ item: ReservationReason }>(),
);

export const createFailure = createAction(
  '[Reservation Reasons] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Reservation Reasons] Update Request',
  props<{
    item: ReservationReason;
    onSuccess?: () => any;
    onFailure?: () => any;
  }>(),
);

export const updateSuccess = createAction(
  '[Reservation Reasons] Update Success',
  props<{ item: ReservationReason }>(),
);

export const updateFailure = createAction(
  '[Reservation Reasons] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Reservation Reasons] Delete Request',
  props<{ id: number }>(),
);

export const deleteSuccess = createAction(
  '[Reservation Reasons] Delete Success',
  props<{ id: number }>(),
);

export const deleteFailure = createAction(
  '[Reservation Reasons] Delete Failure',
  props<{ error: any }>(),
);

export const loadRequest = createAction(
  '[Reservation Reasons] Load Request',
  props<{ params: Record<string, any> }>(),
);

export const loadSuccess = createAction(
  '[Reservation Reasons] Load Success',
  props<{ items: ReservationReason[] }>(),
);

export const loadFailure = createAction(
  '[Reservation Reasons] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Reservation Reasons] Reset State');
