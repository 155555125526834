import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { effectHooks } from '../../helpers';
import { ReservationReasonsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ReservationReasonsStoreEffects {
  constructor(
    private dataService: ReservationReasonsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ params }) =>
        this.dataService.load(params).pipe(
          map((response) => fromActions.loadSuccess({ items: response.data })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ item, onSuccess, onFailure }) =>
        this.dataService.create(item).pipe(
          effectHooks({ onSuccess, onFailure }),
          map((response) =>
            fromActions.createSuccess({ item: response.data[0] }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ item, onSuccess, onFailure }) =>
        this.dataService.update(item).pipe(
          effectHooks({ onSuccess, onFailure }),
          map((response) =>
            fromActions.updateSuccess({ item: response.data[0] }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id }) =>
        this.dataService.delete(id).pipe(
          map(() => fromActions.deleteSuccess({ id })),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
