import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ReservationReasonsStoreEffects } from './effects';
import { reservationReasonsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('reservationReasons', reservationReasonsReducer),
    EffectsModule.forFeature([ReservationReasonsStoreEffects]),
  ],
})
export class ReservationReasonsStoreModule {}
